import {groq} from 'next-sanity';

import type {KeyedArray} from '@/shared/types';

import type {Person, PersonGroup} from '../../shared/types';
import {
	PERSON_IN_CARD_FRAGMENT,
	personFromPersonInCardFragment,
	type PersonInCardFragment,
} from '../query';
import type {PersonGroupSchema} from '../schema';

import type {PersonListGroupSchema} from './schema';

export const PERSON_LIST_GROUP_FRAGMENT = groq`
	...,
	groups[]-> {
		...,
		persons[]-> {
			${PERSON_IN_CARD_FRAGMENT}
		}
	}
`;

export type PersonListGroupFragment = Omit<PersonListGroupSchema, 'groups'> & {
	groups?: (
		| (Omit<PersonGroupSchema, 'persons'> & {
				persons?: (PersonInCardFragment | null)[];
		  })
		| null
	)[];
};

export const personGroupsFromPersonListGroupSection = (
	section: PersonListGroupFragment,
): KeyedArray<PersonGroup> => {
	const groups: KeyedArray<PersonGroup> = [];

	if (section.groups) {
		for (const group of section.groups) {
			if (group) {
				const people: KeyedArray<Person> = [];

				if (group.persons) {
					for (const person of group.persons) {
						if (person) {
							people.push({
								_key: person._id ?? '',
								...personFromPersonInCardFragment(person),
							});
						}
					}
				}

				groups.push({
					_key: group._id ?? '',
					title: group.title ?? '',
					people: people,
				});
			}
		}
	}

	return groups;
};
