import {groq} from 'next-sanity';

import {
	HERO_WITH_BG_IMAGE_FRAGMENT,
	type HeroWithBgImageFragment,
} from './hero-with-bg-image-container/query';
import {heroWithBgImage} from './hero-with-bg-image-container/schema';
import {
	HERO_WITH_SIDE_IMAGE_FRAGMENT,
	type HeroWithSideImageFragment,
} from './hero-with-side-image-container/query';
import {heroWithSideImage} from './hero-with-side-image-container/schema';
import {TEXT_HEADING_FRAGMENT, type TextHeadingFragment} from './text-heading-container/query';
import {textHeading} from './text-heading-container/schema';

export const PAGE_HEAD_FRAGMENT = groq`
	...,
	_type == "${heroWithBgImage().name}" => {
		${HERO_WITH_BG_IMAGE_FRAGMENT}
	},
	_type == "${heroWithSideImage().name}" => {
		${HERO_WITH_SIDE_IMAGE_FRAGMENT}
	},
	_type == "${textHeading().name}" => {
		${TEXT_HEADING_FRAGMENT}
	}
`;

export type PageHeadFragment =
	| TextHeadingFragment
	| HeroWithBgImageFragment
	| HeroWithSideImageFragment;
