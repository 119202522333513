import {groq} from 'next-sanity';

import type {KeyedArray} from '@/shared/types';

import type {Person} from '../../shared/types';
import {
	PERSON_IN_CARD_FRAGMENT,
	personFromPersonInCardFragment,
	type PersonInCardFragment,
} from '../query';

import type {PersonListSelectionSchema} from './schema';

export const PERSON_LIST_SELECTION_FRAGMENT = groq`
	...,
	persons[]-> {
		${PERSON_IN_CARD_FRAGMENT}
	}
`;

export type PersonListSelectionFragment = Omit<PersonListSelectionSchema, 'persons'> & {
	persons: PersonInCardFragment[];
};

export const personListFromPersonListSelectionSection = (
	section: PersonListSelectionFragment,
): KeyedArray<Person> => {
	if (!section.persons) {
		return [];
	}

	return section.persons.map((person) => {
		return {
			_key: person?._id ?? '',
			...personFromPersonInCardFragment(person),
		};
	});
};
