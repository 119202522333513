import type {FC, ReactNode} from 'react';
import clsx from 'clsx';
import Link from 'next/link';

import {ImageBlock} from '@/components/image/ImageBlock';
import {Heading} from '@/components/typography/heading';
import {Paragraph} from '@/components/typography/paragraph';
import {formatDate} from '@/shared';

import type {Article} from '../../shared/types';

type Props = {
	article: Article;
	headingLevel: 2 | 3;
};

export const ArticleCard: FC<Props> = ({article, headingLevel}) => {
	const {title, createdAt, href, imageWeb} = article;

	const formattedDate = formatDate(createdAt);

	return (
		<div className={clsx('relative', 'flex', 'flex-col-reverse')}>
			<div>
				<Heading level={headingLevel} size="medium">
					<CardLink href={href}>{title}</CardLink>
				</Heading>

				<Paragraph asChild>
					<time dateTime={createdAt}>{formattedDate}</time>
				</Paragraph>
			</div>

			{imageWeb && (
				<ImageBlock
					height={imageWeb.image.height}
					src={imageWeb.image.src}
					width={imageWeb.image.width}
					alt={imageWeb.altText ?? ''}
				/>
			)}
		</div>
	);
};

const CardLink: FC<{href: string; children: ReactNode}> = ({href, children}) => {
	return (
		<Link href={href} className={clsx('after:absolute', 'after:inset-0')}>
			{children}
		</Link>
	);
};
