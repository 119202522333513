import type {FC} from 'react';

import {type ImageWeb} from '@/components/image/types';
import {
	HeroWithBgImage,
	heroWithBgImagePortableTextOverrides,
} from '@/components/page-head/hero-with-bg-image';

import {imageWebFromImageWebSchema} from '../../image/query';
import {PortableText} from '../../portable-text';

import type {HeroWithBgImageSchema} from './schema';

interface Props {
	data: HeroWithBgImageSchema;
}

export const HeroWithBgImageContainer: FC<Props> = ({
	data: {backgroundImage, heading, introduction},
}) => {
	let image: ImageWeb | undefined = undefined;

	if (backgroundImage?.asset?._ref) {
		image = imageWebFromImageWebSchema(backgroundImage);
	}

	return (
		<HeroWithBgImage heading={heading} imageWeb={image}>
			{introduction && (
				<PortableText
					blocks={introduction}
					overrideClassNames={heroWithBgImagePortableTextOverrides}
				/>
			)}
		</HeroWithBgImage>
	);
};
