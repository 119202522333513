import {groq} from 'next-sanity';

import type {KeyedArray} from '@/shared/types';

import type {Article} from '../../shared/types';
import type {ArticleInCardFragment} from '../query';
import {ARTICLE_IN_CARD_FRAGMENT, articleInCardFragmentToArticleDetails} from '../query';
import {article} from '../schema';

import type {ArticleListAllSchema} from './schema';

export const ARTICLE_LIST_ALL_FRAGMENT = groq`
	...,
	orderBy == "publishDateNewestFirst" => {
		"articles": *[_type == "${article().name}"] | order(_createdAt desc) {
			${ARTICLE_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "publishDateOldestFirst" => {
		"articles": *[_type == "${article().name}"] | order(_createdAt asc) {
			${ARTICLE_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "titleAtoZ" => {
		"articles": *[_type == "${article().name}"] | order(lower(title) asc) {
			${ARTICLE_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "titleZtoA" => {
		"articles": *[_type == "${article().name}"] | order(lower(title) desc) {
			${ARTICLE_IN_CARD_FRAGMENT}
		}
	},
	!defined(orderBy) => {
		"articles": *[_type == "${article().name}"] | order(_createdAt desc) {
			${ARTICLE_IN_CARD_FRAGMENT}
		}
	}
`;

export type ArticleListAllFragment = ArticleListAllSchema & {
	_type: 'articleListAll';
	articles: ArticleInCardFragment[];
};

export const articleListFromArticleListAllSection = (
	section: ArticleListAllFragment,
): KeyedArray<Article> => {
	const articles: KeyedArray<Article> = [];

	if (!section.articles?.length) {
		return articles;
	}

	for (const articleInCard of section.articles) {
		if (articleInCard) {
			const article = articleInCardFragmentToArticleDetails(articleInCard);
			if (article) {
				articles.push({
					_key: articleInCard._id,
					...article,
				});
			}
		}
	}

	return articles;
};
