import {groq} from 'next-sanity';

import {PORTABLE_TEXT_FRAGMENT} from '@/sanity/components/portable-text/query';

import type {TextWithImageSplitSchema} from './schema';

export const TEXT_WITH_IMAGE_SPLIT_FRAGMENT = groq`
	...,
	content[] {
		${PORTABLE_TEXT_FRAGMENT}
	}
`;

// TODO: This should have the resolved portable text field type:
export type TextWithImageSplitFragment = TextWithImageSplitSchema;
