'use client';

import {useRef} from 'react';
import {useDimensions} from '@selvklart/sanity-next-image';
import type {ImageProps} from 'next/image';
import Image from 'next/image';

import {useDebounce} from '@/hooks/useDebounce';

export interface ImageFillProps
	extends Omit<ImageProps, 'src' | 'fill' | 'objectFit' | 'objectPosition'> {
	src: string;
	width: number;
	height: number;
	hotspot?: {
		x: number;
		y: number;
	} | null;
}

/**
 * Renders an image as an absolutely positioned element filling the containing
 * block completely.
 *
 * Normally this would be used to fill a parent element that has position set
 * to relative.
 *
 * The image is cropped according to the crop rectangle set in Sanity and can
 * be further cropped by setting an aspect ratio. The image hotspot will be
 * taken into consideration (if set) when cropping to fit the image to an
 * aspect ratio.
 *
 * The component will also try to position the image inside the containing
 * block so that the images hotspot is placed as near to the center of the
 * visible area as possible.
 */
export default function ImageFill({
	src,
	width,
	height,
	hotspot,
	style = {},
	...rest
}: ImageFillProps) {
	const ref = useRef<HTMLImageElement>(null);
	const dimensions = useDimensions(ref);
	const debouncedDimensions = useDebounce(dimensions, 1000);

	const imageAspect = width / height;

	// For the first render the measured dimensions will be null as the image
	// element hasn't been mounted yet. This means we won't have a reasonable
	// size to set in the size attribute, causing next.Image to render a blurry
	// 64px version of the image.
	//
	// To get out of that state as quickly as possible, we don't want to
	// debounce the change from null dimensions to actual dimensions. We do
	// that by using the raw measured dimensions until the debounced dimensions
	// change into _something_.
	//
	// If we didn't do this, we would (and did) show a blurry image for a
	// second while we wait for the debounce timer.
	const currentDimensions = debouncedDimensions ?? dimensions;
	let sizes = 0;

	if (currentDimensions) {
		sizes = currentDimensions.width;

		// If the image is wider than the frame it is cropped into, adjust the
		// sizes prop so that it represents the real width of the image,
		// including the parts hidden outside the frame.
		//
		// This ensures we load a variant of the image with enough pixels to
		// show the visible part in full detail.
		const measuredAspect = currentDimensions.width / currentDimensions.height;
		if (imageAspect > measuredAspect) {
			sizes = imageAspect * currentDimensions.height;
		}
	}

	const objectPosition = {
		x: 0,
		y: 0,
	};

	if (dimensions) {
		// If no hotspot is set, we default to the center of the image.
		if (!hotspot) {
			hotspot = {
				x: 0.5,
				y: 0.5,
			};
		}

		const measuredAspect = dimensions.width / dimensions.height;

		const maxOffset = {
			x: 0,
			y: 0,
		};

		const centeringOffset = {
			x: 0,
			y: 0,
		};

		const hotspotOffset = {
			x: 0,
			y: 0,
		};

		if (imageAspect > measuredAspect) {
			const imageRealWidth = dimensions.height * imageAspect;
			const hotspotOffsetX = imageRealWidth * hotspot.x - imageRealWidth * 0.5;

			maxOffset.x = imageRealWidth - dimensions.width;
			maxOffset.y = 0;
			centeringOffset.x = -maxOffset.x / 2;
			centeringOffset.y = 0;
			hotspotOffset.x = hotspotOffsetX;
			hotspotOffset.y = 0;
		} else {
			const imageRealHeight = dimensions.width / imageAspect;
			const hotspotOffsetY = imageRealHeight * hotspot.y - imageRealHeight * 0.5;

			maxOffset.x = 0;
			maxOffset.y = imageRealHeight - dimensions.height;
			centeringOffset.x = 0;
			centeringOffset.y = -maxOffset.y / 2;
			hotspotOffset.x = 0;
			hotspotOffset.y = hotspotOffsetY;
		}

		objectPosition.x = Math.max(-maxOffset.x, Math.min(0, centeringOffset.x - hotspotOffset.x));
		objectPosition.y = Math.max(-maxOffset.y, Math.min(0, centeringOffset.y - hotspotOffset.y));
	}

	return (
		/* eslint-disable jsx-a11y/alt-text */
		<Image
			ref={ref}
			src={src}
			sizes={`${sizes}px`}
			fill
			style={{
				objectFit: 'cover',
				objectPosition: `left ${objectPosition.x}px top ${objectPosition.y}px`,
				...style,
			}}
			{...rest}
		/>
	);
}
