import type {FC} from 'react';

import {
	HeroWithSideImage,
	heroWithSideImagePortableTextOverrides,
} from '@/components/page-head/hero-with-side-image';

import {imageWebFromImageWebSchema} from '../../image/query';
import {PortableText} from '../../portable-text';

import type {HeroWithSideImageSchema} from './schema';

interface Props {
	data: HeroWithSideImageSchema;
}

export const HeroWithSideImageContainer: FC<Props> = ({data: {image, heading, introduction}}) => {
	let sideImage;

	if (image?.asset?._ref) {
		sideImage = imageWebFromImageWebSchema(image);
	}

	return (
		<HeroWithSideImage imageWeb={sideImage} heading={heading}>
			{introduction && (
				<PortableText
					blocks={introduction}
					overrideClassNames={heroWithSideImagePortableTextOverrides}
				/>
			)}
		</HeroWithSideImage>
	);
};
